/* src/app/world-ship-upload/shipments-preview/shipments-preview.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.shipments-preview {
  overflow: auto;
}
.shipments-preview .mat-mdc-form-field {
  display: flex;
}
.shipments-preview .mat-mdc-form-field.form-field--short {
  width: 6rem;
}
.shipments-preview .mat-mdc-form-field.mat-form-field-type-mat-select {
  width: auto;
}
.shipments-preview .mat-mdc-form-field.date-picker {
  width: 10rem;
}
.shipments-preview .blockable {
  position: relative;
}
.shipments-preview .exceptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
}
.shipments-preview .exceptions .glyphicon {
  color: #DF2901;
  font-size: 1.5em;
  margin-right: 0.25em;
}
.shipments-preview .exceptions .popover-content {
  display: none;
  position: absolute;
  top: 2.5rem;
  left: -17rem;
  width: 25rem;
  background-color: white;
  z-index: 99;
  box-shadow: 0 0 2px 0 #004791;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.shipments-preview .exceptions .popover-content::before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(24% - 10px);
  top: -10px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #004791 transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}
.shipments-preview .exceptions .popover-content li {
  padding: 1em;
  white-space: pre-wrap;
  text-align: left;
}
.shipments-preview .exceptions .popover-content li:nth-child(even) {
  background-color: #f0f0ef;
}
.shipments-preview .exceptions:hover .popover-content {
  display: block;
}
.shipments-preview .mat-icon {
  color: #c91a13;
  font-size: 1.8em;
}
.shipments-preview .badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 5px;
  background: #777;
  margin-left: 1em;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 1;
}
/*# sourceMappingURL=shipments-preview.component.css.map */
